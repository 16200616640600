export enum LocationTypeEnum {
    GOOD_STOCK = "good_stock",
    BAD_STOCK = "bad_stock",
    TRANSIT = "transit",
    PACKING = "packing",
    OUTBOUND = "outbound",
}

export const nullableStorageBinLocations = (type: LocationTypeEnum) =>
    type === LocationTypeEnum.TRANSIT || type === LocationTypeEnum.PACKING || type === LocationTypeEnum.OUTBOUND

export const LocationTypeLabels = {
    [LocationTypeEnum.GOOD_STOCK]: "Good Stock",
    [LocationTypeEnum.BAD_STOCK]: "Bad Stock",
    [LocationTypeEnum.TRANSIT]: "Transit",
    [LocationTypeEnum.PACKING]: "Packing",
    [LocationTypeEnum.OUTBOUND]: "Outbound",
}

export const LocationTypeSelectOptions = [
    { value: LocationTypeEnum.GOOD_STOCK, label: LocationTypeEnum.GOOD_STOCK },
    { value: LocationTypeEnum.BAD_STOCK, label: LocationTypeEnum.BAD_STOCK },
    { value: LocationTypeEnum.TRANSIT, label: LocationTypeEnum.TRANSIT },
    { value: LocationTypeEnum.PACKING, label: LocationTypeEnum.PACKING },
    { value: LocationTypeEnum.OUTBOUND, label: LocationTypeEnum.OUTBOUND },
]
