import { useQuery } from "@tanstack/react-query"
import ApiService, { QueryProps } from "@/lib/ApiService"
import { useSearchParams } from "next/navigation"
import { useSession } from "next-auth/react"

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ enableHook = true, param }: QueryProps = {}) => {
    const searchParams = useSearchParams()

    const queryParams = ApiService.toQueryString(param, searchParams)

    const { data: session, status } = useSession()

    return useQuery({
        queryKey: ["fetch-history-storage-bin", queryParams],
        queryFn: async () => {
            try {
                if (status === "authenticated") {
                    const result = await ApiService.getApi(`putaway/findHistoryStorageBin?${queryParams}`, session!.token)

                    if (result.isSuccess) {
                        return {
                            ...result.data,
                        }
                    }
                }

                return {
                    isSuccess: false,
                    message: "Unauthorized",
                }
            } catch (error) {
                return {
                    isSuccess: false,
                    message: (error as Error).message,
                }
            }
        },
        enabled: enableHook && status === "authenticated",
        refetchOnWindowFocus: "always",
    })
}
