import { useQuery } from "@tanstack/react-query"
import { LocationLayoutState } from "@/types/Location"
import InitialLocationLayoutState from "./InitialLocationLayoutState"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return useQuery<LocationLayoutState>({
        queryKey: ["location-layout"],
        initialData: InitialLocationLayoutState,
    })
}
