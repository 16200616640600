import { useQuery } from "@tanstack/react-query"
import ApiService, { QueryProps } from "@/lib/ApiService"
import { useSession } from "next-auth/react"

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ enableHook = true, id }: QueryProps = {}) => {
    const { data: session, status } = useSession()

    return useQuery({
        queryKey: ["fetch-location", id],
        queryFn: async () => {
            try {
                if (status === "authenticated") {
                    const result = await ApiService.getApi(`location/${id}`, session!.token)

                    if (result.isSuccess) {
                        return result.data ?? null
                    }
                }

                return {
                    isSuccess: false,
                    message: "Unauthorized",
                }
            } catch (error) {
                return {
                    isSuccess: false,
                    message: (error as Error).message,
                }
            }
        },
        enabled: enableHook && !!id && status === "authenticated",
        refetchOnWindowFocus: "always",
    })
}
