import { LocationLayoutState } from "@/types/Location"

const InitialLocationLayoutState: LocationLayoutState = {
    isOpen: false,
    selectedId: 0,
    selectedStorageBinId: 0,
    selectableStorageBinFn: undefined,
    editMode: true,
}

export default InitialLocationLayoutState
